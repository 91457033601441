import { gql } from '@apollo/client';

export const GET_PORTFOLIO_EMAILS = gql`
  query GetPortfolioEmails($portfolioCodes: [String!]) {
    portfolioEmails(portfolioCodes: $portfolioCodes) {
      recId
      portfolioCode
      employeeEmail
      employeeFullName
      employeeType
    }
  }
`;
