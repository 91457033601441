import { Card, LinearProgress } from '@mui/material';
import {
  DataGridPremium,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useState } from 'react';
import { Iconify } from '../iconify/Iconify';
import { FileList } from '@bakerweb/models';

const initialRows: FileList[] = [];

interface Props {
  collapseGroups?: boolean;
  handleDownload: (path: string, fileName: string | undefined) => Promise<void>;
  fileNameFormatter?: (fileName: string) => string | undefined;
  files: FileList[];
  loading: boolean;
  showFolder?: boolean;
}

export const FilesDatagrid = ({
  collapseGroups,
  handleDownload,
  fileNameFormatter,
  files,
  loading,
  showFolder
}: Props) => {
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState(initialRows);

  useEffect(() => {
    if (files) {
      setRows(files);
    }
  }, [files]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: showFolder ? ['folder'] : []
      },
      pinnedColumns: {
        right: ['actions']
      },
      columns: {
        columnVisibilityModel: {
          folder: Boolean(showFolder)
        }
      }
    }
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'folder',
        headerName: 'Folder',
        editable: false,
        width: 200,
        aggregable: false,
        type: 'string',
        renderCell: (params) => <strong>{params.value}</strong>
      },
      {
        field: 'fileName',
        headerName: 'File Name',
        editable: false,
        minWidth: 250,
        aggregable: false,
        type: 'string',
        valueFormatter: (value, params) => (fileNameFormatter ? fileNameFormatter(value) : value)
      },
      {
        field: 'lastModified',
        headerName: 'Last Modified',
        editable: false,
        minWidth: 120,
        aggregable: false,
        type: 'date',
        valueGetter: (value) => (value ? new Date(value) : null)
      },
      {
        field: 'size',
        headerName: 'Size',
        editable: false,
        minWidth: 150,
        aggregable: false,
        type: 'number',
        valueFormatter: (value) => (value ? `${(value / 1024).toFixed(2)} KB` : null)
      },
      {
        field: 'fileType',
        headerName: 'File Type',
        editable: false,
        aggregable: false,
        type: 'string'
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params: GridRowParams) =>
          params.row.fileName
            ? [
                <GridActionsCellItem
                  icon={<Iconify icon="eva:cloud-download-outline" />}
                  label="Download"
                  onClick={() => handleDownload(params.row.path, params.row.fileName)}
                />
              ]
            : []
      }
    ],
    []
  );

  return (
    <Card>
      <div style={{ height: '70vh' }}>
        <DataGridPremium
          columns={columns}
          density={'compact'}
          loading={loading}
          rows={rows}
          apiRef={apiRef}
          initialState={initialState}
          getRowId={(row: any) => row.path}
          slots={{ toolbar: GridToolbar, loadingOverlay: () => <LinearProgress /> }}
          defaultGroupingExpansionDepth={collapseGroups ? 0 : -1}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 100, autoFocus: true }
            }
          }}
          sx={{
            // disable cell selection style
            '.MuiDataGrid-cell:focus': {
              outline: 'none'
            },
            // pointer cursor on ALL rows
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer'
            }
          }}
        />
      </div>
    </Card>
  );
};
