import { useUpdateAsOfDate } from '@bakerweb/client-services';
import { FormProvider, RHFNumericField, RHFSelect, RHFTextField } from '@bakerweb/forms';
import { fDateStandard, TAXOPTIONS } from '@bakerweb/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { selectedAsOfDateState } from '@bakerweb/client-state';

type CUProps = {
  totalAssets: number;
  taxesStateRate: number;
  totalCapital: number;
  taxesFedRate: number;
};

export function CUAPMForm() {
  const { updateAsOfDate } = useUpdateAsOfDate();
  const asOfDate = useRecoilValue(selectedAsOfDateState);

  const CUSchema = Yup.object().shape({
    totalAssets: Yup.number().required('Total Assets is required.'),
    totalCapital: Yup.number().required('Total Capital is required.'),
    taxesFedRate: Yup.number().required('Federal Tax Rate is required.').max(100, 'Number cannot exceed 100.'),
    taxesStateRate: Yup.number().required('State Tax Rate is required.').max(100, 'Number cannot exceed 100.')
  });

  const defaultCUValues = {
    totalAssets: undefined,
    taxesStateRate: undefined,
    totalCapital: undefined,
    taxesFedRate: undefined
  };

  const cuMethods = useForm<CUProps>({
    resolver: yupResolver(CUSchema),
    defaultValues: defaultCUValues
  });

  const {
    handleSubmit: handleCUSubmit,
    formState: { isSubmitting: isCUSubmitting, isValid: isCUValid }
  } = cuMethods;

  const onCUSubmit = async (updateData: CUProps) => {
    if (isCUValid) {
      try {
        await updateAsOfDate({
          asOfDate,
          updateData
        });
      } catch (error) {
        console.error('Error submitting CU data', error);
      }
    }
  };

  if (!asOfDate) {
    return <CircularProgress size={20} />;
  }

  return (
    <FormProvider methods={cuMethods} onSubmit={handleCUSubmit(onCUSubmit)}>
      <Stack spacing={3}>
        <Typography variant="caption" paragraph sx={{ color: 'info.main' }}>
          Please DO NOT round values. Use the entire dollar value.
        </Typography>
        <TextField label="Report Date" value={fDateStandard(new Date(asOfDate).toISOString())} disabled />
        <RHFNumericField name="totalAssets" label="Total Assets (Account Code 010)" autoFocus />
        <RHFNumericField name="totalCapital" label="Regulatory Net Worth (Account Code 997)" />
        <RHFSelect
          name="taxesFedRate"
          label="Federal Tax Rate (Top Marginal Rate)"
          InputLabelProps={{
            shrink: true
          }}
        >
          {TAXOPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextField
          InputProps={{
            startAdornment: <></>,
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          name="taxesStateRate"
          label="State Tax Rate"
          type="number"
          variant="outlined"
        />
        <Typography variant="caption" paragraph sx={{ color: 'info.main' }}>
          Note: Risk Adjusted Assets will be unavailable via the Call Report if the bank has opted into the Community
          Bank Leverage Ratio framework. The information provided in this form is to be used for information purposes
          and is not an offer to buy or sell securities.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
        <LoadingButton fullWidth type="submit" variant="contained" size="large" loading={isCUSubmitting}>
          Submit APM
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
