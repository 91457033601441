import { useQuery } from '@apollo/client';
import { PortfolioCustomerLevelDetail } from '@bakerweb/models';
import { useEffect, useState } from 'react';
import { GET_PORTFOLIO_CUSTOMER_LEVEL_DETAILS } from '../graphql/queries/getPortfolioCustomerLevelDetails';
import { useRecoilState } from 'recoil';
import { selectedBakerFedID } from '@bakerweb/client-state';

export const useGetPortfolioCustomerLevelDetails = (portfolioCodes?: string[]) => {
  const [portfolioCustomerLevelDetails, setPortfolioCustomerLevelDetails] = useState<PortfolioCustomerLevelDetail[]>(
    []
  );

  const [bakerFedID, setBakerFedID] = useRecoilState(selectedBakerFedID);
  const { loading, error, data, refetch } = useQuery(GET_PORTFOLIO_CUSTOMER_LEVEL_DETAILS, {
    variables: { portfolioCodes },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.portfolioCustomerLevelDetails) {
      setPortfolioCustomerLevelDetails(data.portfolioCustomerLevelDetails);
      setBakerFedID(data.portfolioCustomerLevelDetails[0]?.bakerFedID);
    }
  }, [data]);

  return { loading, error, portfolioCustomerLevelDetails, refetch };
};
