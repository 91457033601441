import { useHasPermission } from '@bakerweb/client-view-permissions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/bank';

const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    securities: path(ROOTS_DASHBOARD, '/securities'),
    securitiesAll: path(ROOTS_DASHBOARD, '/securities/all'),
    securitiesRiskReward: path(ROOTS_DASHBOARD, '/securities/risk-reward'),
    securitiesBBA: path(ROOTS_DASHBOARD, '/securities/bba'),
    admin: path(ROOTS_DASHBOARD, '/admin'),
    risk: path(ROOTS_DASHBOARD, '/admin/risk'),
    upload: path(ROOTS_DASHBOARD, '/admin/upload'),
    search: path(ROOTS_DASHBOARD, '/search'),
    positions: path(ROOTS_DASHBOARD, '/positions'),
    apm: path(ROOTS_DASHBOARD, '/apm'),
    municredit: path(ROOTS_DASHBOARD, '/muni-credit'),
    cds: path(ROOTS_DASHBOARD, '/cds'),
    loans: path(ROOTS_DASHBOARD, '/loans'),
    files: path(ROOTS_DASHBOARD, '/files'),
    cusip: path(ROOTS_DASHBOARD, '/cusip/:positionId'),
    users: path(ROOTS_DASHBOARD, '/users')
  },
  pledging: {
    root: path(ROOTS_DASHBOARD, '/pledging'),
    portfolio: path(ROOTS_DASHBOARD, '/pledging/portfolio'),
    pledgeCodes: path(ROOTS_DASHBOARD, '/pledging/codes'),
    pledgeSafekeeping: path(ROOTS_DASHBOARD, '/pledging/safekeeping'),
    reports: path(ROOTS_DASHBOARD, '/pledging/reports')
  }
};

export const useClientSidebarConfig = () => {
  const { hasPermissionToViewPath } = useHasPermission();
  const getItems = () => {
    return [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: <DashboardIcon />
      },
      {
        title: 'Securities',
        path: PATH_DASHBOARD.general.securities,
        icon: <MonetizationOnIcon />,
        children: [
          { title: 'All Securities', path: PATH_DASHBOARD.general.securitiesAll },
          { title: 'Risk Reward', path: PATH_DASHBOARD.general.securitiesRiskReward },
          { title: 'BBA', path: PATH_DASHBOARD.general.securitiesBBA }
        ]
      },
      {
        title: 'Pledging',
        path: PATH_DASHBOARD.pledging.root,
        icon: <AssuredWorkloadIcon />,
        children: [
          { title: 'My Portfolio', path: PATH_DASHBOARD.pledging.portfolio },
          { title: 'Pledge Codes', path: PATH_DASHBOARD.pledging.pledgeCodes },
          { title: 'Safekeeping', path: PATH_DASHBOARD.pledging.pledgeSafekeeping },
          { title: 'Pledging Reports', path: PATH_DASHBOARD.pledging.reports }
        ]
      },
      {
        title: 'Muni Credit',
        path: PATH_DASHBOARD.general.municredit,
        icon: <AccountBalanceIcon />
      },
      {
        title: 'Files',
        path: PATH_DASHBOARD.general.files,
        icon: <FolderOpenIcon />
      },
      {
        title: 'APM Supplemental',
        path: PATH_DASHBOARD.general.apm,
        icon: <MonitorHeartIcon />
      },
      {
        title: 'Prospectus Search',
        path: PATH_DASHBOARD.general.search,
        icon: <ManageSearchIcon />
      }
    ].filter(({ path }) => hasPermissionToViewPath(path));
  };

  const config = [
    {
      subheader: '',
      items: getItems()
    },
    {
      subheader: 'Broker Dealer Services',
      items: [
        {
          title: 'CD Offerings',
          path: PATH_DASHBOARD.general.cds,
          icon: <CardMembershipIcon />
        },
        {
          title: 'Loans',
          path: PATH_DASHBOARD.general.loans,
          icon: <HandshakeIcon />
        }
      ]
    }
  ];

  return config;
};
