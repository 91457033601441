import { useUpdateAsOfDate } from '@bakerweb/client-services';
import { FormProvider, RHFNumericField, RHFSelect, RHFTextField } from '@bakerweb/forms';
import { fDateStandard, TAXOPTIONS } from '@bakerweb/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';
import { selectedAsOfDateState } from '@bakerweb/client-state';

type APMProps = {
  totalAssets: number;
  taxesStateRate: number;
  totalRiskBasedAssets: number;
  totalTier1Capital: number;
  totalCapital: number;
  taxesFedRate: number;
  taxesCostOfFunds: number;
};

export function APMForm() {
  const { updateAsOfDate } = useUpdateAsOfDate();
  const asOfDate = useRecoilValue(selectedAsOfDateState);

  const APMSchema = Yup.object().shape({
    totalAssets: Yup.number().required('Total Assets is required.'),
    totalRiskBasedAssets: Yup.number().required('Total Risk Adjusted Assets is required.'),
    totalTier1Capital: Yup.number().required('Tier 1 Capital is required.'),
    totalCapital: Yup.number().required('Total Capital is required.'),
    taxesFedRate: Yup.number().required('Federal Tax Rate is required.').max(100, 'Number cannot exceed 100.'),
    taxesStateRate: Yup.number().required('State Tax Rate is required.').max(100, 'Number cannot exceed 100.'),
    taxesCostOfFunds: Yup.number()
      .required('TEFRA Cost of Funds is required.')
      .positive()
      .min(0.1, 'Number needs to be greater than 0.')
      .max(5.0, 'Number cannot exceed 5.00')
  });

  const defaultAPMValues = {
    totalAssets: undefined,
    taxesStateRate: undefined,
    totalRiskBasedAssets: undefined,
    totalTier1Capital: undefined,
    totalCapital: undefined,
    taxesFedRate: undefined,
    taxesCostOfFunds: undefined
  };
  const apmMethods = useForm<APMProps>({
    resolver: yupResolver(APMSchema),
    defaultValues: defaultAPMValues
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = apmMethods;

  const onAPMSubmit = async (updateData: APMProps) => {
    if (isValid) {
      try {
        await updateAsOfDate({
          asOfDate,
          updateData
        });
      } catch (error) {
        console.error('Error submitting APM data', error);
      }
    }
  };

  if (!asOfDate) {
    return <CircularProgress size={20} />;
  }

  return (
    <FormProvider methods={apmMethods} onSubmit={handleSubmit(onAPMSubmit)}>
      <Stack spacing={3}>
        <Typography variant="caption" paragraph sx={{ color: 'info.main' }}>
          Please DO NOT round values. Use the entire dollar value.
        </Typography>
        <TextField label="Report Date" value={fDateStandard(new Date(asOfDate).toISOString())} disabled />
        <RHFNumericField name="totalAssets" label="Total Assets (RC, Item 12)" autoFocus />
        <RHFNumericField name="totalRiskBasedAssets" label="Risk Adjusted Assets (RC-R, Part 1, Item 48)" />
        <RHFNumericField name="totalTier1Capital" label="Tier 1 Capital (RC-R, Part 1, Item 26)" />
        <RHFNumericField name="totalCapital" label="Total Capital (RC-R, Part 1, Item 47)" />
        <RHFSelect
          name="taxesFedRate"
          label="Federal Tax Rate (Top Marginal Rate)"
          InputLabelProps={{
            shrink: true
          }}
        >
          {TAXOPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </RHFSelect>
        <RHFTextField
          InputProps={{
            startAdornment: <></>,
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          name="taxesStateRate"
          label="State Tax Rate"
          type="number"
          variant="outlined"
        />
        <RHFTextField
          InputProps={{
            startAdornment: <></>,
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          name="taxesCostOfFunds"
          label="TEFRA Cost of Funds (YTD)"
          helperText="(Total Int Exp (Annualized) / Average Total Assets) * 100"
          type="number"
        />

        <Typography variant="caption" paragraph sx={{ color: 'info.main' }}>
          Note: Risk Adjusted Assets will be unavailable via the Call Report if the bank has opted into the Community
          Bank Leverage Ratio framework. The information provided in this form is to be used for information purposes
          and is not an offer to buy or sell securities.
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
        <LoadingButton fullWidth type="submit" variant="contained" size="large" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
