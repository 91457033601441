import { gql } from '@apollo/client';

export const GET_PORTFOLIO_CUSTOMER_LEVEL_DETAILS = gql`
  query GetPortfolioCustomerLevelDetails($portfolioCodes: [String!]) {
    portfolioCustomerLevelDetails(portfolioCodes: $portfolioCodes) {
      bakerFedID
      portfolioCode
    }
  }
`;
