import { useQuery } from '@apollo/client';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { PortfolioEmail } from '@bakerweb/models';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GET_PORTFOLIO_EMAILS } from '../graphql/queries/getPortfolioEmails';

export const useGetPortfolioEmails = () => {
  const [portfolioEmails, setPortfolioEmails] = useState<PortfolioEmail[]>([]);
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioCode = portfolio?.portfolioCode;
  const { loading, error, data, refetch } = useQuery(GET_PORTFOLIO_EMAILS, {
    variables: { portfolioCodes: [portfolioCode] },
    skip: !portfolioCode,
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data && data.portfolioEmails) {
      setPortfolioEmails(data.portfolioEmails);
    }
  }, [data]);

  return { loading, error, portfolioEmails, refetch };
};
