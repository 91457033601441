import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers {
    users {
      email
      enabled
      accounts {
        id
        name
        portfolioCodes
      }
      portal
      portfolioCodes
      permissions {
        pledging
        pledgeAndRelease
        apm
        files
        cds
        muniCredit
        dailyPricing
      }
      cusipLimit
    }
  }
`;
