import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($userCreateDto: UserCreateDto!) {
    createUser(userCreateDto: $userCreateDto) {
      email
      enabled
      accounts {
        id
        name
        portfolioCodes
      }
      portal
      portfolioCodes
      permissions {
        pledging
        pledgeAndRelease
        apm
        files
        cds
        muniCredit
        dailyPricing
      }
      cusipLimit
    }
  }
`;
