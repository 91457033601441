import { ClientDashboardLayout, LoadingScreen, LogoOnlyLayout } from '@bakerweb/layouts';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

const Loadable = (Component: any) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/bank');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

async function checkUserSession() {
  try {
    await getCurrentUser();
  } catch {
    await signOut();
  }
}

export default function Router() {
  checkUserSession();
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/bank/dashboard" replace />
    },
    {
      path: '/bank/pledging',
      element: <Navigate to="/bank/pledging/portfolio" replace />
    },
    {
      path: '/bank/securities',
      element: <Navigate to="/bank/securities/all" replace />
    },
    {
      path: 'bank',
      element: <ClientDashboardLayout />,
      children: [
        { element: <Navigate to="/bank/dashboard" replace /> },
        {
          path: 'dashboard',
          element: <DashboardPage />
        },
        {
          path: 'securities',
          children: [
            {
              path: 'all',
              element: <SecuritiesAllPage />
            },
            {
              path: 'risk-reward',
              element: <SecuritiesRiskRewardPage />
            },
            {
              path: 'bba',
              element: <SecuritiesBBAPage />
            }
          ]
        },
        {
          path: 'pledging',
          children: [
            {
              path: 'portfolio',
              element: <PledgingPortfolioPage />
            },
            {
              path: 'codes',
              element: <PledgeManageCodesPage />
            },
            {
              path: 'safekeeping',
              element: <PledgeSafekeepingPage />
            },
            {
              path: 'reports',
              element: <PledgingReportsPage />
            }
          ]
        },
        {
          path: 'muni-credit',
          element: <MuniCreditPage />
        },
        {
          path: 'cds',
          element: <CDOfferingsPage />
        },
        {
          path: 'files',
          element: <FilesPage />
        },
        {
          path: 'apm',
          element: <ApmPage />
        },
        {
          path: 'search',
          element: <SearchPage />
        },
        {
          path: 'cds',
          element: <CDOfferingsPage />
        },
        {
          path: 'loans',
          element: <LoansPage />
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

const SearchPage = Loadable(lazy(() => import('../pages/SearchPage')));
const PledgingPortfolioPage = Loadable(lazy(() => import('../pages/PledgingPortfolioPage')));
const PledgeManageCodesPage = Loadable(lazy(() => import('../pages/PledgeManageCodesPage')));
const PledgeSafekeepingPage = Loadable(lazy(() => import('../pages/PledgeSafekeepingPage')));
const PledgingReportsPage = Loadable(lazy(() => import('../pages/PledgingReportsPage')));
const CDOfferingsPage = Loadable(lazy(() => import('../pages/CDOfferingsPage')));
const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));
const ApmPage = Loadable(lazy(() => import('../pages/ApmPage')));
const MuniCreditPage = Loadable(lazy(() => import('../pages/MuniCreditPage')));
const FilesPage = Loadable(lazy(() => import('../pages/FilesPage')));
const SecuritiesAllPage = Loadable(lazy(() => import('../pages/SecuritiesAllPage')));
const SecuritiesRiskRewardPage = Loadable(lazy(() => import('../pages/SecuritiesRiskRewardPage')));
const SecuritiesBBAPage = Loadable(lazy(() => import('../pages/SecuritiesBBAPage')));
const NotFound = Loadable(lazy(() => import('../pages/404Page')));
const LoansPage = Loadable(lazy(() => import('../pages/LoansPage')));
