import { replace } from 'lodash';
import numeral from 'numeral';
import { fDateStandard } from './formatTime';

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fCurrencyForPledgingGrid(number: number) {
  const isNegative = number < 0;
  const formattedNumber = numeral(Math.abs(number)).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
  return isNegative ? `+${formattedNumber}` : formattedNumber;
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.00%');
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export const format8Decimals = (value?: number) => (value != null ? value.toFixed(8) : '');
export const format3Decimals = (value?: number) => (value != null ? value.toFixed(3) : '');
export const format2Decimals = (value?: number) => (value != null ? value.toFixed(2) : '');
export const format1Decimal = (value?: number) => (value != null ? value.toFixed(1) : '');
export const formatDate = (value?: string) => (value ? fDateStandard(value) : '');
export const formatAlphanumeric = (value?: any) => (value != null ? value.toString() : '');
