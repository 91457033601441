import { useGetAsOfDates } from '@bakerweb/client-services';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { selectedAsOfDateState } from '@bakerweb/client-state';
import { useRecoilState } from 'recoil';
import { fAsOfDate, fDateStandard } from '@bakerweb/utils';

// NOTE: Currently only applicable (globally) to Dashboard page, Securities page, Pledging pages
export function GlobalReportDateDropdown() {
  const { asOfDates, loading } = useGetAsOfDates();
  const [date, setDate] = useRecoilState(selectedAsOfDateState);
  const dateShown = useMemo(() => (date ? fDateStandard(new Date(date).toISOString()) : ''), [date]);

  useEffect(() => {
    if (!date && !loading && asOfDates.length) {
      setDate(fAsOfDate(asOfDates[0].asOfDate));
    }
  }, [date, loading, asOfDates]);

  const handleDateChange = (event: SelectChangeEvent<string>) => {
    setDate(fAsOfDate(event.target.value));
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <FormControl fullWidth variant="outlined" sx={{ mt: 2 }} aria-label="date selector">
      <InputLabel id="asOfDate-select-label">Report Date</InputLabel>
      <Select
        labelId="asOfDate-select-label"
        value={dateShown}
        onChange={handleDateChange}
        label="Select Report Date"
        variant="outlined"
      >
        {asOfDates.slice(0, 12).map(({ asOfDate }) => {
          return (
            <MenuItem key={asOfDate} value={asOfDate}>
              {asOfDate}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
